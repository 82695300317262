<template>
  <div class="phone-card">
    <b-modal
      v-model="modal"
      @ok="inactivate"
    >
      Are you sure?
    </b-modal>
    <div class="row">
      <div class="col-6">
        <div class="phone-card__phone">
          Phone: <a :href="`tel:+${phone.phoneNumber}`">{{ phoneNumber }}</a>
        </div>
        <div class="phone-card__name">
          Name: {{ phone.name }}
        </div>
        <div class="phone-card__created">
          Created: {{ dateCreated }}
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-12">
            Type: {{ phone.type }}
          </div>
          <div
            v-if="phone.leadSource"
            class="phone-card__source col-12"
          >
            Lead Source: {{ phone.leadSource }}
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="phone-card__actions">
              <my-button
                class="phone-card__action"
                title="edit"
                type="button"
                variant="secondary"
                :on-click="edit"
              />
              <my-button
                class="phone-card__action"
                title="inactivate"
                type="button"
                variant="secondary"
                :on-click="showInactivateModal"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import MyButton from './Button.vue';

export default {
  name: 'PhoneCard',
  components: {
    MyButton,
  },
  props: {
    phone: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modal: false,
    };
  },
  computed: {
    dateCreated() {
      const date = new Date(this.phone.dtCreated);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return `${year}/${month}/${day}`;
    },
    phoneNumber() {
      const phoneRaw = this.phone.phoneNumber;
      const firstPart = phoneRaw.slice(0, 3);
      const secondPart = phoneRaw.slice(3, 6);
      const thirdPart = phoneRaw.slice(6, 10);
      return `(${firstPart}) ${secondPart}-${thirdPart}`;
    },
  },
  methods: {
    ...mapMutations([
      'setEditWindowVisibility',
      'editWindowEditMode',
      'setEditWindowInfo',
    ]),
    ...mapActions([
      'deletePhoneNumber',
    ]),
    edit() {
      this.setEditWindowVisibility(true);
      this.setEditWindowInfo(this.phone);
      this.editWindowEditMode();
    },
    inactivate() {
      this.deletePhoneNumber(this.phone.id);
    },
    showInactivateModal() {
      this.modal = true;
    },
  },
};
</script>

<style scoped lang="scss">
  .phone-card {
    border-radius: $default-border-radius;
    box-shadow: $default-box-shadow;
    padding: rem(10) rem(20);
    margin: rem(15) 0;
    &__actions {
      display: flex;
      justify-content: center;
    }
    &__action {
      margin: rem(5);
    }
  }
</style>
