<template>
  <div
    v-if="showEditWindow"
    class="edit-window"
  >
    <div class="heading-3">
      {{ editWindowTitle }}
    </div>
    <b-form
      novalidate
      @submit.prevent="onSubmit"
    >
      <b-alert
        :show="$v.$anyError && saveDirty"
        fade
        variant="danger"
      >
        Please correct the errors below
      </b-alert>
      <b-alert
        :show="showServerError"
        variant="danger"
      >
        {{ serverErrorMessage }}
      </b-alert>
      <div class="edit-window__form-group">
        <div class="row">
          <div class="col-3">
            <div class="heading-1">
              Phone
            </div>
          </div>
          <div class="col-9">
            <b-form-input
              id="phone-number-input"
              v-model="$v.phoneNumber.$model"
              required
              class="edit-window__input"
              :class="{'form-error':$v.phoneNumber.$error && saveDirty}"
              type="text"
              placeholder="Phone Number"
            />
            <error-label
              for-label="phone-number-input"
              :show-condition="$v.phoneNumber.$error && saveDirty"
              error-message="This field is required"
            />
          </div>
        </div>
      </div>
      <div class="edit-window__form-group">
        <div class="row">
          <div class="col-3">
            <div class="heading-1">
              Name
            </div>
          </div>
          <div class="col-9">
            <b-form-input
              id="name-input"
              v-model="$v.name.$model"
              :class="{'form-error':$v.name.$error && saveDirty}"
              class="edit-window__input"
              required
              type="text"
              placeholder="Name"
            />
            <error-label
              for-label="name-input"
              :show-condition="$v.name.$error && saveDirty"
              error-message="This field is required"
            />
          </div>
        </div>
      </div>
      <div class="edit-window__form-group">
        <div class="row">
          <div class="col-3">
            <div class="heading-1">
              Comments
            </div>
          </div>
          <div class="col-9">
            <b-form-textarea
              id="comments-input"
              v-model="$v.comments.$model"
              :class="{'form-error':$v.comments.$error && saveDirty}"
              class="edit-window__input"
              placeholder="Comments"
              rows="3"
              max-rows="10"
            />
            <error-label
              for-label="comments-input"
              :show-condition="$v.comments.$error && saveDirty"
              error-message="This field is required"
            />
          </div>
        </div>
      </div>
      <div class="edit-window__form-group">
        <div class="row">
          <div class="col-3">
            <div class="heading-1">
              Type
            </div>
          </div>
          <div class="col-9">
            <b-form-select
              id="type-input"
              v-model="$v.type.$model"
              class="edit-window__input"
              :class="{'form-error':$v.type.$error && saveDirty}"
              required
            >
              <b-form-select-option :value="null">
                -Select-
              </b-form-select-option>
              <b-form-select-option value="TV">
                TV
              </b-form-select-option>
              <b-form-select-option value="DIGITAL">
                Digital
              </b-form-select-option>
              <b-form-select-option value="RADIO">
                Radio
              </b-form-select-option>
            </b-form-select>
            <error-label
              for-label="type-input"
              :show-condition="$v.type.$error && saveDirty"
              error-message="This field is required"
            />
          </div>
        </div>
      </div>
      <div class="edit-window__form-group">
        <div class="row">
          <div class="col-3">
            <div class="heading-1">
              Lead Source
            </div>
          </div>
          <div class="col-9">
            <b-form-select
              id="leadSource-input"
              v-model="$v.leadSourceId.$model"
              class="edit-window__input"
              :class="{'form-error':$v.leadSourceId.$error && saveDirty}"
              :options="leadSourceList"
              required
            />
            <error-label
              for-label="type-input"
              :show-condition="$v.leadSourceId.$error && saveDirty"
              error-message="This field is required"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="edit-window__actions">
            <my-button
              class="edit-window__action"
              title="Save"
              type="button"
              variant="secondary"
              :on-click="doSavePhoneNumber"
              :is-loading="isLoadingSaveNumber"
            />
            <my-button
              class="edit-window__action"
              title="Cancel"
              type="button"
              variant="secondary"
              :on-click="doCancelEdit"
            />
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';
import { required, numeric } from 'vuelidate/lib/validators';
import MyButton from './Button.vue';
import ErrorLabel from './ErrorLabel.vue';

const editWindowTitles = {
  add: 'Add New Phone Number',
  edit: 'Edit Phone Number',
};
export default {
  name: 'EditWindow',
  components: {
    MyButton,
    ErrorLabel,
  },
  data() {
    return {
      isLoadingSaveNumber: false,
      saveDirty: false,
      showServerError: false,
      serverErrorMessage: '',
      leadSourceList: [],
    };
  },
  validations() {
    return {
      phoneNumber: {
        required,
        numeric,
      },
      comments: {
        required,
      },
      type: {
        required,
      },
      name: {
        required,
      },
      leadSourceId: {
        required,
      },
    };
  },
  computed: {
    ...mapState([
      'showEditWindow',
      'editWindowTitle',
    ]),
    name: {
      get() {
        return this.$store.state.name;
      },
      set(value) {
        this.setNameWindowInfo(value);
      },
    },
    comments: {
      get() {
        return this.$store.state.comments;
      },
      set(value) {
        this.setCommentsWindowInfo(value);
      },
    },
    type: {
      get() {
        return this.$store.state.type;
      },
      set(value) {
        this.setTypeWindowInfo(value);
      },
    },
    phoneNumber: {
      get() {
        return this.$store.state.phoneNumber;
      },
      set(value) {
        this.setPhoneWindowInfo(value);
      },
    },
    leadSourceId: {
      get() {
        return this.$store.state.leadSourceId;
      },
      set(value) {
        this.setLeadSourceWindowInfo(value);
      },
    },
  },
  async created() {
    try {
      this.leadSourceList = [{ value: null, text: '-Select-' }, ...(await this.getLeadSourceList()).map((item) => ({
        value: item.id,
        text: item.leadSource,
      }))];
    } catch (message) {
      this.showServerError = true;
      this.serverErrorMessage = message;
    }
  },
  methods: {
    ...mapMutations([
      'setEditWindowVisibility',
      'setNameWindowInfo',
      'setCommentsWindowInfo',
      'setTypeWindowInfo',
      'setPhoneWindowInfo',
      'setLeadSourceWindowInfo',
    ]),
    ...mapActions([
      'addNewPhoneNumber',
      'editPhoneNumber',
      'getLeadSourceList',
    ]),
    async doSavePhoneNumber() {
      if (!this.saveDirty) {
        this.saveDirty = true;
        this.$v.$touch();
      }
      this.showServerError = false;
      if (!this.$v.$anyError) {
        this.isLoadingSaveNumber = true;
        try {
          if (this.editWindowTitle === editWindowTitles.add) {
            await this.addNewPhoneNumber();
            this.$toasted.global.success({
              message: 'New phone added',
            });
          } else {
            await this.editPhoneNumber();
            this.$toasted.global.success({
              message: 'Successfully updated a phone number',
            });
          }
        } catch (message) {
          this.serverErrorMessage = message;
          this.showServerError = true;
        } finally {
          this.isLoadingSaveNumber = false;
        }
      }
    },
    doCancelEdit() {
      this.setEditWindowVisibility(false);
    },
  },
};
</script>

<style scoped lang="scss">
  .edit-window {
    border-radius: $default-border-radius;
    box-shadow: $default-box-shadow;
    padding: rem(10) rem(20);
    margin: rem(15) 0;
    &__input {
      margin-bottom: rem(15);
    }
    &__actions {
      display: flex;
      justify-content: center;
      margin-top: rem(10);
    }
    &__action {
      margin: rem(5);
    }
  }
  .form-error {
    border: 1px solid red !important;
    margin-bottom: 0 !important;
    &:focus {
      border-color: red ;
    }
  }
</style>
