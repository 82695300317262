<template>
  <label
    :for="forLabel"
    class="error"
    :class="{'error--show' : showCondition}"
  >
    {{ showCondition ? errorMessage : "" }}
  </label>
</template>

<script>
export default {
  name: 'ErrorLabel',
  props: {
    errorMessage: {
      type: String,
      required: true,
    },
    forLabel: {
      type: String,
      required: true,
    },
    showCondition: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
    .error {
        display: block;
        opacity: 0;
        transform: translateY(-10px);
        color: red;
        font-size: rem(14);
        transition: all 0.5s ease;
        &--show {
            transform: translateY(0);
            opacity: 1;
        }
    }
</style>
