<template>
  <div class="action-bar">
    <my-button
      class="action-bar__new-phone"
      title="New Phone"
      type="button"
      variant="secondary"
      :on-click="openNewPhoneEditWindow"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import MyButton from './Button.vue';

export default {
  name: 'ActionBar',
  components: {
    MyButton,
  },
  methods: {
    ...mapMutations([
      'setEditWindowVisibility',
      'editWindowNewMode',
    ]),
    openNewPhoneEditWindow() {
      this.setEditWindowVisibility(true);
      this.editWindowNewMode();
    },
  },
};
</script>

<style scoped lang="scss">
  .action-bar {
    display: flex;
    justify-content: flex-end;
    margin-bottom: rem(10);
  }
</style>
