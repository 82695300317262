<template>
  <div class="phone-card-list">
    <div
      v-if="searchMode"
      class="phone-card-list__search-mode"
    >
      <div class="heading-3">
        Search results
      </div>
      <div
        class="phone-card-list__go-back"
        @click="getPhoneNumbers"
      >
        &lt;Go back
      </div>
    </div>
    <b-alert
      :show="showServerError"
      variant="danger"
    >
      {{ serverErrorMessage }}
    </b-alert>
    <div
      v-if="isLoadingNumbersList"
      class="phone-card-list__loading"
    >
      <b-spinner label="Spinning" />
    </div>
    <div
      v-for="(phone, i) in phoneNumberList"
      v-else
      :key="i"
    >
      <phone-card :phone="phone" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import PhoneCard from './PhoneCard.vue';

export default {
  name: 'PhoneCardList',
  components: {
    PhoneCard,
  },
  data() {
    return {
      showServerError: false,
      serverErrorMessage: '',
    };
  },
  computed: {
    ...mapState([
      'phoneNumberList',
      'isLoadingNumbersList',
      'searchMode',
    ]),
  },
  async created() {
    try {
      await this.getPhoneNumbers();
    } catch (message) {
      this.showServerError = true;
      this.serverErrorMessage = message;
    }
  },
  methods: {
    ...mapActions([
      'getPhoneNumbers',
    ]),
  },
};
</script>

<style scoped lang="scss">
  .phone-card-list {
    &__loading {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50vh
    }
    &__go-back {
      margin-bottom: rem(30);
      color: $color-primary;
      &:hover {
          cursor: pointer;
      }
    }
  }
</style>
