<template>
  <div class="dashboard-main">
    <div class="container-fluid">
      <div class="row">
        <div class="col-6">
          <phone-card-list />
        </div>
        <div class="col-6">
          <edit-window />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditWindow from './EditWindow.vue';
import PhoneCardList from './PhoneCardList.vue';

export default {
  name: 'DashboardMain',
  components: {
    EditWindow,
    PhoneCardList,
  },
};
</script>

<style scoped lang="scss">
  .dashboard-marin {
    margin-top: rem(10);
  }
</style>
