import Vue from 'vue';
import Vuex from 'vuex';

import paths from '../api/paths';
import api from '../api/axios';
import formatErrors from './middleware';

Vue.use(Vuex);

const editWindowTitles = {
  add: 'Add New Phone Number',
  edit: 'Edit Phone Number',
};

export default new Vuex.Store({
  state: {
    isLoadingNumbersList: false,
    phoneNumberList: [],
    showEditWindow: false,
    editWindowTitle: editWindowTitles.edit,
    editWindowInfo: {},
    name: '',
    type: null,
    phoneNumber: '',
    comments: '',
    id: '',
    leadSourceId: null,
    searchMode: false,
  },
  getters: {
  },
  mutations: {
    setEditWindowVisibility(state, newValue) {
      state.showEditWindow = newValue;
    },
    setPhoneNumberList(state, newValue) {
      state.phoneNumberList = newValue;
    },
    editWindowNewMode(state) {
      state.editWindowTitle = editWindowTitles.add;
      state.name = '';
      state.type = null;
      state.phoneNumber = '';
      state.comments = '';
      state.id = '';
      state.leadSourceId = null;
    },
    editWindowEditMode(state) {
      state.editWindowTitle = editWindowTitles.edit;
    },
    setEditWindowInfo(state, newValue) {
      state.name = newValue.name;
      state.phoneNumber = newValue.phoneNumber;
      state.type = newValue.type;
      state.comments = newValue.comments;
      state.id = newValue.id;
      state.leadSourceId = newValue.leadSourceId;
    },
    setPhoneWindowInfo(state, newValue) {
      state.phoneNumber = newValue;
    },
    setCommentsWindowInfo(state, newValue) {
      state.comments = newValue;
    },
    setTypeWindowInfo(state, newValue) {
      state.type = newValue;
    },
    setNameWindowInfo(state, newValue) {
      state.name = newValue;
    },
    setLeadSourceWindowInfo(state, newValue) {
      state.leadSourceId = newValue;
    },
    setSearchMode(state, newValue) {
      state.searchMode = newValue;
    },
  },
  actions: {
    getLeadSourceList() {
      const headers = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'x-api-key': '0c5c381bdc4bf205b50d0c05fad428e88a2797e926e76103389fd7d0051b6c6b',
      };
      return new Promise((resolve, reject) => {
        api.get(paths.LEAD_SOURCE, { headers })
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            const message = formatErrors(response);
            reject(message);
          });
      });
    },
    getPhoneNumbers({ commit, state }, args) {
      const headers = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'x-api-key': '0c5c381bdc4bf205b50d0c05fad428e88a2797e926e76103389fd7d0051b6c6b',
      };
      let params = {};
      if (args && args.inactive !== undefined) {
        commit('setSearchMode', true);
        params = {
          includeInactive: args.inactive,
          keywords: args.query,
        };
      } else {
        commit('setSearchMode', false);
      }
      state.isLoadingNumbersList = true;
      return new Promise((resolve, reject) => {
        api.get(paths.PHONES, {
          params,
          headers,
        })
          .then(({ data }) => {
            commit('setPhoneNumberList', data);
            resolve(data);
          })
          .catch(({ response }) => {
            const message = formatErrors(response);
            reject(message);
          })
          .finally(() => {
            state.isLoadingNumbersList = false;
          });
      });
    },
    // eslint-disable-next-line no-empty-pattern
    addNewPhoneNumber({ state, dispatch }) {
      const headers = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'x-api-key': '0c5c381bdc4bf205b50d0c05fad428e88a2797e926e76103389fd7d0051b6c6b',
      };
      return new Promise((resolve, reject) => {
        const {
          name, phoneNumber, comments, type, leadSourceId,
        } = state;

        const payload = {
          name, phoneNumber, comments, type, leadSourceId,
        };

        api.post(paths.PHONES, payload, { headers })
          .then(({ data }) => {
            resolve(data);
            dispatch('getPhoneNumbers');
          })
          .catch(({ response }) => {
            const message = formatErrors(response);
            reject(message);
          });
      });
    },
    editPhoneNumber({ dispatch, state }) {
      const headers = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'x-api-key': '0c5c381bdc4bf205b50d0c05fad428e88a2797e926e76103389fd7d0051b6c6b',
      };
      return new Promise((resolve, reject) => {
        const {
          name, phoneNumber, comments, type, id, leadSourceId,
        } = state;

        const payload = {
          name, phoneNumber, comments, type, id, leadSourceId,
        };
        api.put(paths.PHONES, payload, { headers })
          .then(({ data }) => {
            resolve(data);
            dispatch('getPhoneNumbers');
          })
          .catch(({ response }) => {
            const message = formatErrors(response);
            reject(message);
          });
      });
    },
    deletePhoneNumber({ dispatch }, payload) {
      const headers = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'x-api-key': '0c5c381bdc4bf205b50d0c05fad428e88a2797e926e76103389fd7d0051b6c6b',
      };
      return new Promise((resolve, reject) => {
        api.delete(`${paths.PHONES}/${payload}`, { headers })
          .then(({ data }) => {
            resolve(data);
            dispatch('getPhoneNumbers');
          })
          .catch(({ response }) => {
            const message = formatErrors(response);
            reject(message);
          });
      });
    },
  },
  modules: {
  },
});
