<template>
  <button
    class="button"
    :class="{
      'button--secondary': variant === 'secondary',
      'button--disabled' : disabled,
      'button--error' : variant === 'error'
    }"
    :type="type"
    :disabled="disabled"
    @click="onClickWrapper"
  >
    <div
      v-if="!isLoading"
      class="button__title"
    >
      {{ title }}
    </div>
    <b-spinner
      v-if="isLoading"
      class="button__spinner"
      variant="light"
      small
    />
  </button>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    type: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClickWrapper() {
      if (!this.isLoading) {
        this.onClick();
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .button {
    width: 100%;
    height: rem(45);
    border: none;
    border-radius: 1000000rem;
    background-color: $color-primary;
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: $color-primary-hover;
    }
    &--secondary {
      width: auto;
      height: auto;
      padding: rem(7) rem(20);
      border-radius: 10000rem;
      @include media-breakpoint-down(md) {
         width: 100%;
      }
    }
    &--error {
      width: auto;
      height: auto;
      padding: rem(7) rem(20);
      border-radius: 10000rem;
      @include media-breakpoint-down(md) {
         width: 100%;
      }
      background-color: red !important;
      &:hover {
        background-color: rgb(209, 30, 30) !important;
      }
    }
    &--disabled {
      background-color: $button-disabled-color;
      &:hover {
        background-color: $button-disabled-color;
      }
    }
    &__title {
      color: $color-white;
      text-transform: capitalize;
      font-weight: bold;
    }
    &__spinner {
      margin-bottom: rem(3);
    }
  }
</style>
