<template>
  <div class="search-bar">
    <div class="search-bar__search">
      <b-form-input
        v-model="searchText"
        type="search"
        class="search-bar__input"
        placeholder="Search for telephone numbers"
        @keyup.enter="doSearch"
      />
      <my-button
        class="search-bar__button"
        title="search"
        type="button"
        :on-click="doSearch"
      />
    </div>
    <div class="search-bar__checkbox">
      <b-form-checkbox
        v-model="searchInactive"
        name="inactive-checkbox"
        :value="true"
        :unchecked-value="false"
      >
        Include inactive
      </b-form-checkbox>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import MyButton from './Button.vue';

export default {
  name: 'SearchBar',
  components: {
    MyButton,
  },
  data() {
    return {
      searchInactive: false,
      searchText: '',
    };
  },
  methods: {
    ...mapActions([
      'getPhoneNumbers',
    ]),
    async doSearch() {
      try {
        const args = {
          inactive: this.searchInactive,
          query: this.searchText,
        };
        await this.getPhoneNumbers(args);
      } catch (message) {
        this.$toasted.global.error({
          message,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .search-bar {
    &__search {
      display: flex;
      align-items: center;
      margin-bottom: rem(10);
    }
    &__button {
      height: rem(40);
      width: rem(150);
    }
    &__input {
      height: rem(40);
      margin-right: rem(20);
    }
  }
</style>
