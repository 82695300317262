<template>
  <div class="dashboard">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="dashboard__search-bar">
            <search-bar />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="dashboard__action-bar">
            <action-bar />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="dashboard__main">
            <dashboard-main />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchBar from '../components/SearchBar.vue';
import ActionBar from '../components/ActionBar.vue';
import DashboardMain from '../components/DashboardMain.vue';

export default {
  name: 'Dashboard',
  components: {
    SearchBar,
    ActionBar,
    DashboardMain,
  },
};
</script>

<style scoped lang="scss">
    .dashboard {
        &__main {
            height: calc(100vh - 150px);
        }
        &__search-bar {
            width: 50%;
            margin: rem(10) auto;
        }
    }
</style>
