export default function formatErrors(response) {
  const timestamp = ` Error Number ${Date.now()}`;
  let errorToReturn;
  if (!response) {
    errorToReturn = `Unknown server error. Please try again.${timestamp}`;
  }
  const { data } = response;
  if (data.displayedMessage) {
    errorToReturn = data.displayedMessage;
  } else if (data.message) {
    errorToReturn = data.message + timestamp;
  } else if (data.error) {
    errorToReturn = data.error + timestamp;
  } else {
    errorToReturn = `Unknown server error. Please try again.${timestamp}`;
  }
  if (errorToReturn.toLowerCase().includes('zoho')) {
    errorToReturn = `Unknown server error. Please try again.${timestamp}`;
  }
  return errorToReturn;
}
